import React from "react";
import { Link } from "gatsby";

export default function Header() {
  return (
    <header className="hf_wrapper">
      <Link to="/" className="inner_block">
        漫画更新チェッカー
      </Link>
      <Link to="/list" className="inner_block">
        一覧
      </Link>
    </header>
  );
}
