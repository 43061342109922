import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>ページが見つかりません。</h1>
      <section>
        <Link to="/">トップへ戻る</Link>
      </section>
    </Layout>
  );
}

export default NotFoundPage

export const Head = () => <title>ページが見つかりません。</title>
