import React from "react";

export default function Footer() {
  return (
    <footer className="hf_wrapper">
      <a
        href="https://marshmallow-qa.com/machiper0823"
        className="inner_block"
        target="_blank"
        rel="noopener noreferrer"
      >
        ご要望(マシュマロ)
      </a>
      <a
        href="https://twitter.com/machiper0823"
        className="inner_block"
        target="_blank"
        rel="noopener noreferrer"
      >
        管理者
      </a>
    </footer>
  );
}
